.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background: var(--white);
    color: var(--white);
    border-bottom: 2px solid var(--black);
}

.navbar-image {
    max-height: 75px; /* Adjust this value to control the initial size */
    max-width: 150px; /* Adjust this value to control the initial size */
    height: auto; /* Maintains the aspect ratio */
    width: auto; /* Maintains the aspect ratio */
    vertical-align: middle;
    margin-left: 10px;
    margin-right: 20px;
    cursor: pointer;
}

.navbar-button {
    background: transparent;
    border: none;
    font-size: 1rem;
    text-shadow: 0 0 1px rgba(0,0,0,0.5);
    text-decoration: none;
    color: var(--black);
    cursor: pointer;
    margin-right: 15px;
}
