/* Container for the modal, covering the entire viewport */
.modal-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1000; /* Ensure it's above other content */
    overflow: auto; /* Allows scrolling if needed */
}

/* Your existing modal styles, with positioning removed */
.modal {
    background-color: var(--white);
    padding: 20px;
    border-radius: 20px;
    width: 500px; /* Adjust based on your content */
    max-width: 90%; /* Prevents the modal from being too wide on small screens */
    box-shadow: 0 4px 6px rgba(0,0,0,0.1); /* Optional: Adds some depth */
}

.modal-header {
    display: flex;
    justify-content: center;
}


.modal .formControlsContainer {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.modal-actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    gap: 5px;
}

.create-merchant-button {
    max-width: fit-content;
    background-color: var(--sdx-bright-blue) !important;
}

.cancel-merchant-button {
    max-width: fit-content;
    color: var(--sdx-bright-blue) !important;
}

