.table-container {
  padding-left: 20px;
  padding-right: 20px;
}

/* Apply styles to the table */
table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px; /* Add some space between the header and the table */
  margin-bottom: 20px; /* Add some space between the table and pagination buttons */
}

/* Style table headers */
th {
  background-color: #f2f2f2;
  color: #333;
  font-weight: bold;
  padding: 12px;
  text-align: left;
}

/* Style table cells */
td {
  border: 1px solid #ddd;
  padding: 12px;
}

/* Alternate row colors */
tr:nth-child(even) {
  background-color: #f9f9f9;
}

/* Hover effect on rows */
tr:hover {
  background-color: #f2f2f2;
}

/* Responsive table layout */
@media screen and (max-width: 600px) {
  table {
      border: 0;
  }

  thead {
      display: none;
  }

  tr {
      border-bottom: 3px solid #ddd;
      display: block;
      margin-bottom: 10px;
  }

  td {
      border-bottom: none;
      display: block;
      text-align: right; /* Align cell content right for better readability */
  }

  td:before {
      content: attr(data-label);
      font-weight: bold;
      display: inline-block;
      width: 50%;
      margin-right: 5%;
      text-align: left;
  }
}

.platform-logo {
  max-height: 50px; /* Adjust this value to control the initial size */
  max-width: 100px; /* Adjust this value to control the initial size */
  height: auto; /* Maintains the aspect ratio */
  width: auto; /* Maintains the aspect ratio */
  vertical-align: middle;
  cursor: pointer;
}

.icon-button {
  vertical-align: middle;
  cursor: pointer;
}

.cell-text {
  vertical-align: middle;
}

.controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.pagination-controls {
  display: flex;
  justify-content: left;
  align-items: center;
}

.pagination-controls .form-control {
  margin-right: 20px;
}

.form-control .form-label {
  top: -8px;
}

.form-control .MuiSelect-outlined {
  min-width: 75px;
}

.create-button {
  background-color: var(--sdx-bright-blue) !important;
  justify-content: right;
}

/* TODO BELOW */
.overlay {
  position: fixed; /* Sit on top of the page content */
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5); /* Black background with opacity */
  z-index: 2; /* Specify a stack order in case you're using a z-index scale */
  cursor: pointer; /* Add a pointer on hover */
}

.modal-content {
  z-index: 3; /* Ensure modal content is above overlay */
  /* Your existing modal content styles */
}
