:root {
  --white: white;
  --light-grey: #F4F4F4;
  --sdx-light-gray-blue: #EBEDF4;
  --sdx-turquoise: #15D4E0;
  --sdx-bright-blue: #1209CB;
  --sdx-dark-blue: #0E2266;
  --black: black;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
